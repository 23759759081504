import React, { useEffect, useCallback, useState } from 'react'
import { Helmet } from 'react-helmet'
import Slider from 'react-slick'
import Typewriter from "typewriter-effect"
import Tooltip from '@mui/material/Tooltip';
// import './public/assets/js/plugins/plugin-custom'
// import '../public/assets/js/plugins/plugin-custom.js';
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'
import Modal from 'react-modal'
import ReactCardFlip from 'react-card-flip'
import moment from 'moment';
import $ from 'jquery';
import { useDropzone } from 'react-dropzone'
import { GoogleLogin } from '@react-oauth/google';
import FacebookLogin from "react-facebook-login";
import { CookiesProvider, useCookies } from "react-cookie";
import CryptoJS from 'crypto-js';
import Cookies from 'js-cookie';
import CookieConsent from "react-cookie-consent";
import { BrowserRouter as Router, json, Prompt } from "react-router-dom";
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import RankComponent from './ReactRank';
var randomstring = require("randomstring")
var FormData = require('form-data')


// import ReactTooltip from 'react-tooltip';
// import { json } from 'react-router-dom';

// window.jQuery = jQuery;

// https://www.cookieyes.com/cookie-policy/

// const clientId = '1075148302060-9vo78g6i085cca72ud985qchaaddsd3u.apps.googleusercontent.com';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
    },
};
export default function Home() {
    const [sliderRef, setSliderRef] = useState(null);
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [isFlipped1, setIsFlipped1] = useState(false);
    const [isFlipped2, setIsFlipped2] = useState(false);
    const [isSidebar, setSidebar] = useState(false);
    const [data, setData] = useState([]);
    const [users, setUsers] = useState([]);
    const [coins, setCoins] = useState(0);
    const [show, setShow] = useState(false);
    const [FinalModal, setForgetpasswords] = useState(false);
    const [editProfile, setProfiles] = useState(false);
    const [profilePicture, setProfilePicture] = useState();
    const [isRevealPwd, setIsRevealPwd] = useState(false);
    const [isConformRevealPwd, setIsConformRevealPwd] = useState(false);
    const [support, setSupport] = useState(false);
    const [supportPicture, setSupportPicture] = useState();
    const [gametrack, setGametrack] = useState(true);
    const [store, setStore] = useState([]);
    const [activeItem, setActiveItem] = useState('home');
    const [cookies, setCookie] = useCookies(['user']);
    const [email, setMail] = useState('');
    const [Pwd, setPwd] = useState('');
    const [isCheck, setCheck] = useState(false);
    const [isFormIncomplete, setIsFormIncomplete] = useState(true);
    const currentYear = new Date().getFullYear();
    const [download, setDownload] = useState(0);
    const [gameUser, setgameUser] = useState(0);
    const [experts, setExperts] = useState(0);
    const [launchCount, setLaunchCount] = useState(0);

    const handleItemClick = (item) => {
        setActiveItem(item);

    };
    useEffect(() => {        

        // const receiveMessage = (event) => {        
        //     console.log("event is:::::receiveMessage:::::: event ----data", event.data);
        //     if (event.data !== undefined && event.data !== null && event.data.length < 15) {      

        //         var upcoins = {
        //             upcoins:  event.data,
        //             id: data._id
        //         }                
        //         fetch('https://throwcards.io/setCoins', {
        //             method: 'POST',
        //             body: JSON.stringify(upcoins)
        //         })
        //         .then((res) => res.json())
        //         .then((response) => {                                       
        //         })
        //         .catch((err) => {
        //         });

        //         setCoins({chips: Number(event.data)});                
        //             var logdata = {};
        //             logdata = sessionStorage.getItem('loginData');
        //             logdata = JSON.parse(logdata);
        //             logdata.data.myInfo.chip = Number(event.data);
        //             sessionStorage.setItem('loginData', JSON.stringify(logdata));          
            
        //     }
        // };

        const receiveMessage = (event) => {
            console.log('Received message from iframe from api:', event.data);
            if (event.data !== undefined && event.data !== null) {
                console.log('Received message from iframe from api:', event.data);
                var upcoins = Number(event.data);
                console.log("upcoins is", upcoins);
                logdata = sessionStorage.getItem('loginData');
                logdata = JSON.parse(logdata);
                logdata.data.myInfo.chip = Number(event.data);
                logdata.chips = Number(event.data);
                sessionStorage.setItem('loginData', JSON.stringify(logdata));
                setCoins({ chips: upcoins });
                var upcoins = {
                    upcoins:  event.data,
                    id: data._id
                } 
                fetch('https://throwcards.io/setCoins', {
                    method: 'POST',
                    body: JSON.stringify(upcoins)
                })
                .then((res) => res.json())
                .then((response) => {   
                    console.log("respose is::::::::::::::data:::::::::;", response.data);                                                      
                })
                .catch((err) => {
                });
            }
        };

        window.addEventListener('message', receiveMessage, false);


        window.addEventListener('message', receiveMessage, false);
        sessionStorage.setItem('Downloads', '500');
        sessionStorage.setItem('GameUser', '2');
        sessionStorage.setItem('Experts', '52');
        sessionStorage.setItem('launchCount', '4');

        var Download = sessionStorage.getItem('Downloads');
        setDownload(Download);
        var gameUsers = sessionStorage.getItem('GameUser');
        setExperts(gameUsers);
        var experts = sessionStorage.getItem('Experts');
        setExperts(experts);
        var launchCount = sessionStorage.getItem('launchCount');
        setLaunchCount(launchCount);

       

        if (sessionStorage.getItem("login")) {
            var logdata = {}
            logdata = sessionStorage.getItem('loginData');
            logdata = JSON.parse(logdata);
            setData(logdata);
            setCoins({ chips: Number(logdata.data.myInfo.chip) });
            
        } else {

        }
        fetch('https://throwcards.io/getAlldata', {
            method: 'POST',
            body: ''
        })
            .then((res) => res.json())
            .then((response) => {
                setUsers(response.data);
                setCoins({chip: Number(response.data.myInfo.chip)});            
            })
            .catch((err) => {
            });
        return () => {
            
           
            if (Cookies.get('abcemil')) {

                const cookieemail = Cookies.get('abcemil');
                const cookiepassword = Cookies.get('psdlog');
                const secretKey = 'klmnopqrstuv';
                const decryptedBytesemail = CryptoJS.AES.decrypt(cookieemail, secretKey);
                const decryptedEmail = decryptedBytesemail.toString(CryptoJS.enc.Utf8);

                const decryptedBytesPwd = CryptoJS.AES.decrypt(cookiepassword, secretKey);
                const decryptedPwd = decryptedBytesPwd.toString(CryptoJS.enc.Utf8);


                var getLoginObj = {
                    email: decryptedEmail,
                    password: decryptedPwd
                }

                fetch('https://throwcards.io/getLogindata', {
                    method: 'POST',
                    body: JSON.stringify(getLoginObj),
                    headers: {
                        'Content-type': 'application/json;   charset=UTF-8',
                    },
                })
                .then((res) => res.json())
                .then((post) => {

                    if (post.error !== true) {
                        // setData(post.data);                    
                        sessionStorage.setItem('login', true);
                        sessionStorage.setItem('loginData', JSON.stringify(post));
                        setData(post);
                        setCoins({ chips: Number(post.data.myInfo.chip) });                                             
                    } else {

                        Swal.fire({
                            icon: 'error',
                            title: 'incorrect',
                            text: 'Please Enter The username and Password is currect!'
                        }).then((response) => {

                        });
                    }
                })
                .catch((err) => {
                });
                } 
            
        };
    }, []);

    const setCookiesData = (e) => {
        setCookie('myAwesomeCookieName2', true, { path: '/' });
    }
    const onDropZone1Drop = async (acceptedFiles) => {

        var formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        /* acceptedFiles.forEach((file) => {            
            formData.append('t', 1);  
            
            //formData.file = file;                   
        }); */


        fetch('https://throwcards.io/uploads', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Process the JSON data here

                setProfilePicture(data.imageURL);
                sessionStorage.setItem('imgUrl', data.imageURL);

            })
            .catch(error => console.error(error));
    };

    const onDropZone2Drop = async (acceptedFiles) => {

        var formData = new FormData();
        formData.append("file", acceptedFiles[0]);
        // acceptedFiles.forEach((file) => {            
        //     formData.append("files", acceptedFiles[0]); 

        // formData.file = acceptedFiles[0];                   
        // }); 


        fetch('https://throwcards.io/supportUpload', {
            method: 'POST',
            body: formData
        })
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Process the JSON data here

                setSupportPicture(data.imageURL);
                sessionStorage.setItem('supportUrl', data.imageURL);

            })

    };

    const { getRootProps: getRootProps1, getInputProps: getInputProps1 } = useDropzone({
        onDrop: onDropZone1Drop,
        accept: 'image/*',
    });

    const { getRootProps: getRootProps2, getInputProps: getInputProps2 } = useDropzone({
        onDrop: onDropZone2Drop,
    });

    const sliderSettings = {
        // removes default buttons
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        autoplay: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
            {
                breakpoint: 576,
                settings: {
                    arrows: false,
                    slidesToShow: 1,
                },
            },
        ],
    }

    const termsService = (e) => {

        if (e.target.checked === true) {
            setCheck(true);
        } else {
            setCheck(false)
        }
    }

    const handleClick = (e) => {
        if (sessionStorage.getItem("login")) {
            if (window.innerWidth >= 769) {
                setIsFlipped2(!isFlipped2);
                $('.run-games').html(`'<div id="iframe"><iframe src="https://throwcards.io/bgame?id=${data.data._id}&name=${data.data.fname}&mail=${data.data.email}&profile=${data.data.profile}&coin=${data.data.chips},&GameName=${"bhabhiCardGame"}" width="100%" height="100%" frameBorder="0" targeted=_blank allowFullScreen></iframe></div>'`);


                var GameTrackingObj = {
                    gameName: 'Bhabhicardgame',
                    userName: data.data.fname,
                    uid: data.data._id,
                    chips: data.data.chips,
                    cd: moment(new Date()).format('lll')
                }



                fetch('https://throwcards.io/GameTracking', {
                    method: 'POST',
                    body: JSON.stringify(GameTrackingObj),
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8',
                    },
                })
                    .then((res) => res.json())
                    .then((response) => {
                        setGametrack(response);


                    })
                    .catch((err) => {
                    });

            } else {
                Swal.fire({
                    title: 'Warning...!',
                    text: 'Please note that Unity WebGL is not currently supported on mobiles. Press OK if you wish to continue anyway.',
                    customClass: {
                        popup: 'custom-swal',
                    },

                }).then((response) => {

                });
            }
        } else {
            setIsOpen(true);
        }

    };

    const returenFlipped = (e) => {
        setIsFlipped2(!isFlipped2);

        var Gamesession = {
            gameName: gametrack.data.ops[0].gameName,
            userName: gametrack.data.ops[0].fname,
            id: gametrack.data.ops[0]._id,
            chips: gametrack.data.ops[0].chips,
            endDate: moment(new Date()).format('lll'),
            deff: 0

        }


        fetch('https://throwcards.io/Gamesession', {
            method: 'POST',
            body: JSON.stringify(Gamesession),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {

                if (post.error !== true) {
                    $('#iframe').remove();
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The currect Email!',
                        customClass: {
                            popup: 'Forcustome',
                        },
                    }).then((response) => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
            });

    }

    const handleflip = (e) => {
        e.preventDefault();
        setIsFlipped1(!isFlipped1);
    };

    Modal.setAppElement('#root');

    function openModal() {

        // fetch('https://throwcards.io/getLogindata')
        //     .then(response => response.json())
        //     .then(data => {

        //         setData(data);
        //         if (data.Logintype === true) {
        //             setSidebar(true);
        //         } else {
        //             setSidebar(true);
        //         }
        //     })
        //     .catch(error => {
        //         console.error('Error fetching session data:', error);
        //     });



        if (Cookies.get('abcemil')) {

            const cookieemail = Cookies.get('abcemil');
            const cookiepassword = Cookies.get('psdlog');
            const secretKey = 'klmnopqrstuv';
            const decryptedBytesemail = CryptoJS.AES.decrypt(cookieemail, secretKey);
            const decryptedEmail = decryptedBytesemail.toString(CryptoJS.enc.Utf8);

            const decryptedBytesPwd = CryptoJS.AES.decrypt(cookiepassword, secretKey);
            const decryptedPwd = decryptedBytesPwd.toString(CryptoJS.enc.Utf8);



            var getLoginObj = {
                email: decryptedEmail,
                password: decryptedPwd
            }

            fetch('https://throwcards.io/getLogindata', {
                method: 'POST',
                body: JSON.stringify(getLoginObj),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((res) => res.json())
                .then((post) => {

                    if (post.error !== true) {
                        // setData(post.data);                    
                        sessionStorage.setItem('login', true);
                        sessionStorage.setItem('loginData', JSON.stringify(post));
                        setData(post);
                        setCoins({ chips: Number(post.data.myInfo.chip) });                        

                        // window.location.reload();
                        setSidebar(true);
                    } else {

                        Swal.fire({
                            icon: 'error',
                            title: 'incorrect',
                            text: 'Please Enter The username and Password is currect!'
                        }).then((response) => {

                        });
                    }
                })
                .catch((err) => {
                });



        } else {

            setIsOpen(true);
        }
    }

    const handleJoining = (e) => {
        if (!sessionStorage.getItem('login')) {
            setIsOpen(true);
        }
    }

    function afterOpenModal() {

    }
    function closeModal() {
        setIsOpen(false);
    }
    const closeSidebar = (e) => {
        setSidebar(false);
    }
    const forgetpassword = (e) => {
        setShow(true);
    }
    const closeforget = (e) => {
        setShow(false);
    }
    const closeforgetfinalmodal = (e) => {
        setForgetpasswords(false);
    }
    const ForgetSubmit = (e) => {
        e.preventDefault();
        var ForgetData = new FormData(e.target);
        var ForgetDataobject = Object.fromEntries(ForgetData.entries());
        ForgetDataobject.token = randomstring.generate(16);
        ForgetDataobject.url = 'https://throwcards.io/userForgetpassword?email=${ForgetDataobject.email}&token=${ForgetDataobject.token}&url=${ForgetDataobject.url}';

        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes,',
            customClass: {
                popup: 'Forcustome',
            },
        }).then((result) => {

            if (result.isConfirmed) {

                fetch(`https://throwcards.io/userForgetpassword?email=${ForgetDataobject.email}&token=${ForgetDataobject.token}&url=${ForgetDataobject.url}`, {
                    method: 'Get',
                    headers: {
                        'Content-type': 'application/json; charset=UTF-8'
                    },
                })
                    .then((res) => res.json())
                    .then((post) => {

                        if (post.error === false) {
                            var data = post.data.ops[0];
                            sessionStorage.setItem("emailId", data.email);
                            // setData(postdata);
                            sessionStorage.setItem("token", data.token);
                            setForgetpasswords(true);
                        }
                    })
            } else {
                window.location.reload();
            }

        })

    }
    const FinalForgetSubmite = (e) => {
        e.preventDefault();
        const FinalForgetData = new FormData(e.target);
        const FinalForgetDataObject = Object.fromEntries(FinalForgetData.entries());

        var mail = sessionStorage.getItem("emailId");
        var token = sessionStorage.getItem("token");
        FinalForgetDataObject.mail = mail;
        FinalForgetDataObject.token = token;
        if (FinalForgetDataObject.npassword === FinalForgetDataObject.cpassword) {
            fetch('https://throwcards.io/ForgetPasswords', {
                method: 'POST',
                body: JSON.stringify(FinalForgetDataObject),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                },
            })
                .then((res) => res.json())
                .then((post) => {

                    if (post.error !== true) {
                        Swal.fire(
                            'Congretulatios!',
                            'Your Password Updated..',
                            'success'
                        ).then((response) => {
                            window.location.reload();
                        });
                    } else {
                        Swal.fire({
                            icon: 'error',
                            title: 'incorrect',
                            text: 'Please Enter The currect Email!',
                            customClass: {
                                popup: 'Forcustome',
                            },
                        }).then((response) => {
                            window.location.reload();
                        });
                    }
                })
                .catch((err) => {
                });
        } else {
            Swal.fire({
                icon: 'error',
                title: 'incorrect',
                text: 'Please Enter The Same Password!'
            }).then((response) => {
            });
        }

    }

    const onSubmit = (e, user) => {
        const secretKey = 'klmnopqrstuv';
        const encryptedEmail = CryptoJS.AES.encrypt(email, secretKey).toString();
        const encryptedPwd = CryptoJS.AES.encrypt(Pwd, secretKey).toString();
        setCookie('abcemil', encryptedEmail, { path: '/' });
        setCookie('psdlog', encryptedPwd, { path: '/' });
        e.preventDefault();
        const LoginData = new FormData(e.target);
        const LoginDataObject = Object.fromEntries(LoginData.entries());


        fetch('https://throwcards.io/userLogin', {
            method: 'POST',
            body: JSON.stringify(LoginDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then(post => {
                // Update the state with the API response.
                if (post.error !== true) {
                    // setData(post.data);                    
                    sessionStorage.setItem('login', true);
                    sessionStorage.setItem('loginData', JSON.stringify(post));
                    setData(post);

                    window.location.reload();
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The username and Password is currect!'
                    }).then((response) => {

                    });
                }
            })
            .catch((err) => {

            });
    }
    const onSubmitRegister = (e) => {
        e.preventDefault();
        const LoginData = new FormData(e.target);
        const RegisterDataObject = Object.fromEntries(LoginData.entries());
        RegisterDataObject.coins = 1000;
        RegisterDataObject.profile = '/assets/images/product-img-001.png';


        fetch('https://throwcards.io/userRegister', {
            method: 'POST',
            body: JSON.stringify(RegisterDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                if (post.error === true) {

                    Swal.fire({
                        icon: 'error',
                        title: 'Password not match',
                        text: 'Please Enter The Same Password in Confrom Password!'
                    })
                } else {
                    var loging = post.isloggedIn;

                    sessionStorage.setItem("isLoggedIn", loging);
                    window.location.reload();
                    setIsOpen(true);
                }
            })
            .catch((err) => {

            });
    }
    const logout = (response) => {
        var obj = {};
        obj = sessionStorage.getItem("loginData");
        fetch('https://throwcards.io/userLogout', {
            method: 'POST',
            body: obj,
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {
                sessionStorage.clear();
                window.location.reload();
                Cookies.remove('abcemil');
                Cookies.remove('psdlog');
            })
            .catch((err) => {

            });
    }
    const ProfileDataSubmite = (e) => {
        e.preventDefault();
        const Profiledata = new FormData(e.target);
        const ProfileDataObject = Object.fromEntries(Profiledata.entries());
        var img = sessionStorage.getItem('imgUrl');
        ProfileDataObject.profile = img;


        fetch('https://throwcards.io/updateProfile', {
            method: 'POST',
            body: JSON.stringify(ProfileDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {

                if (post.error !== true) {
                    var updateddata = post;
                    setData(updateddata);
                    sessionStorage.setItem('loginData', JSON.stringify(post));
                    Swal.fire(
                        'Congratulations!',
                        'Your Profile Has Been Updated..',
                        'success'
                    ).then((response) => {

                        window.location.reload();
                    });
                } else {

                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The username and Password is currect!'
                    }).then((response) => {

                    });
                }

            })
            .catch((err) => {

            });
    }

    const handleSupport = (e) => {
        setSupport(true);
    }
    const closesupport = (e) => {
        setSupport(false);
    }

    const supportSubmit = (e) => {
        e.preventDefault();
        const supporData = new FormData(e.target);
        const supportObject = Object.fromEntries(supporData.entries());
        var suppFile = sessionStorage.getItem('supportUrl');
        supportObject.supportfile = suppFile;


        fetch('https://throwcards.io/suportData', {
            method: 'POST',
            body: JSON.stringify(supportObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {

                if (post.error !== true) {
                    Swal.fire(
                        'Congratulations!',
                        'Your Message Has Been Send',
                        'success'
                    ).then((response) => {

                        window.location.reload();
                    });
                }
            })
            .catch((err) => {

            });
    }
    const responseMessage = (response) => {

    };
    const errorMessage = (error) => {

    };
    const responseFacebook = (response) => {

    };

    const handleEdit = (e) => {
        fetch('https://throwcards.io/getLogindata')
            .then(response => response.json())
            .then(data => {

                setData(data);
            })
            .catch(error => {
                console.error('Error fetching session data:', error);
            });
        setProfiles(true);

    }
    const closeEditprofile = (e) => {
        setProfiles(false);
    }

    const onSubmitContact = (e) => {
        e.preventDefault();
        const Contactdata = new FormData(e.target);
        const ContactDataObject = Object.fromEntries(Contactdata.entries());


        fetch('https://throwcards.io/contactus', {
            method: 'POST',
            body: JSON.stringify(ContactDataObject),
            headers: {
                'Content-type': 'application/json; charset=UTF-8',
            },
        })
            .then((res) => res.json())
            .then((post) => {

                if (post.error !== true) {
                    Swal.fire(
                        'Congretulatios!',
                        'Your Message has been Send',
                        'success'
                    ).then((response) => {
                        window.location.reload();
                    });
                } else {
                    Swal.fire({
                        icon: 'error',
                        title: 'incorrect',
                        text: 'Please Enter The currect Email!',
                        customClass: {
                            popup: 'Forcustome',
                        },
                    }).then((response) => {
                        window.location.reload();
                    });
                }
            })
            .catch((err) => {
            });

    }

    // var logintype = sessionStorage.getItem('login');

    return (

        <div>

            <Helmet>
                {/* <script src='../assets/js/main.js'></script> */}
            </Helmet>


            <div className="App">

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="tab-content flipper" id="flipper">
                                                <ReactCardFlip isFlipped={isFlipped1} flipDirection="vertical">
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                            <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                                aria-label="Close" onClick={closeModal}>
                                                                <i className="material-symbols-outlined fs-two"> close </i>
                                                            </button>
                                                        </div>
                                                        <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                            aria-labelledby="loginArea-tab">
                                                            <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                                <li className="bottom-area" role="presentation">
                                                                    <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                        data-bs-target="#loginArea" type="button" role="tab"
                                                                        aria-controls="loginArea" aria-selected="true">
                                                                        Login
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="login-content">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={onSubmit} id="formdata" action="#" className="p-4 p-sm-8">
                                                                        <p>Sign up for faster checkout, special offers, and customer
                                                                            support!</p>
                                                                        <div className="form-inside mt-6 p-3 p-sm-6">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">Email*</label>
                                                                                        <input type="email" id="loginemail" name="email" value={email}
                                                                                            placeholder="Enter your email" onChange={(e) => setMail(e.target.value)} autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginpassword">Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                            <input type={isRevealPwd ? "text" : "password"} id="loginpassword" name="password" value={Pwd} placeholder="Enter your Password" onChange={(e) => setPwd(e.target.value)} autoComplete="off" required />

                                                                                            {isRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex justify-content-start'>
                                                                                    <a style={{ color: "#dff00e" }} onClick={forgetpassword}>Forget Your Password ?</a>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                        <label
                                                                                            className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8">
                                                                                            <span className="text-start d-flex gap-1">
                                                                                                I agree to the <a href='/terms&condition' style={{ color: '#dff00e', fontWeight: "600" }} target='_blank'>Terms of
                                                                                                    Services</a>
                                                                                            </span>
                                                                                            <input type="checkbox" name="agree" onChange={termsService} />
                                                                                            <span className="checkmark start-0"></span>
                                                                                        </label>
                                                                                    </div>

                                                                                    <div>
                                                                                        {isCheck === true ?
                                                                                            <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                                <button className="box-style btn-box" style={{animation: "none"}}>
                                                                                                    Login
                                                                                                </button>
                                                                                            </div> :
                                                                                            <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                                <button className="box-style btn-box" disabled>
                                                                                                    Login
                                                                                                </button>
                                                                                            </div>
                                                                                        }

                                                                                    </div>
                                                                                    {/* <p className="text-center" style={{marginLeft: "30px", lineHeight: "3"}}>Or</p>                                                                     */}
                                                                                    {/* <div className='modal-footer position-relative d-flex justify-content-start'> */}

                                                                                    {/* <div className="btn-area mt-2 mt-sm-5">
                                                                                        <button className="box-style btn-box">
                                                                                            Google Login
                                                                                        </button>
                                                                                    </div> */}


                                                                                    {/* <GoogleLogin
                                                                                            clientId={clientId}
                                                                                            buttonText="Login with Google"
                                                                                            onSuccess={sucessGoogle}
                                                                                            onFailure={failedGoogle}
                                                                                            cookiePolicy={'single_host_origin'}
                                                                                        /> */}
                                                                                    {/* <GoogleLogin onSuccess={responseMessage} onError={errorMessage} /> */}

                                                                                    {/* <FacebookLogin
                                                                                            style={{
                                                                                                backgroundColor: '#4267b2',
                                                                                                color: '#fff',
                                                                                                fontSize: '16px',
                                                                                                padding: '12px 24px',
                                                                                                border: 'none',
                                                                                                borderRadius: '4px',
                                                                                            }}
                                                                                            appId="1394690131257414"
                                                                                            onSuccess={(response) => {
                                                                                                
                                                                                            }}
                                                                                            onFail={(error) => {
                                                                                                
                                                                                            }}
                                                                                            onProfileSuccess={(response) => {
                                                                                                
                                                                                            }}
                                                                                        /> */}

                                                                                    {/* <FacebookLogin
                                                                                            appId="1394690131257414"
                                                                                            autoLoad={false}
                                                                                            fields="name,email,picture"
                                                                                            onSuccess={responseFacebook}
                                                                                        /> */}
                                                                                    {/* <a href='#!'>
                                                                                            <img src='assets/images/login/facebook button_.png' className='mt-5' width={200} alt='img' />
                                                                                        </a> */}
                                                                                    {/* </div> */}

                                                                                </div>
                                                                                <div className='text-center' style={{ color: "#dff00e" }}>
                                                                                    You don't have an account yet!
                                                                                    <a href='#!' onClick={handleflip} className="flipbutton" style={{ lineHeight: "0" }} id="loginButton"><strong> Register Now</strong></a>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                    {/* <a href='https://throwcards.io/facebook'>
                                                                        <div className="btn-area facebook-btn mt-2 mt-sm-5">
                                                                            <button className="box-style btn-box">
                                                                                Facebook Login
                                                                            </button>
                                                                        </div>
                                                                    </a> */}

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-content">
                                                        <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                            <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                                aria-label="Close" onClick={closeModal}>
                                                                <i className="material-symbols-outlined fs-two"> close </i>
                                                            </button>
                                                        </div>
                                                        <div className=" back tab-pane fade" id="regArea" role="tabpanel"
                                                            aria-labelledby="regArea-tab">
                                                            <ul className="nav flex-nowrap log-reg-btn justify-content-center">

                                                                <li className="bottom-area" role="presentation">
                                                                    <button className="nav-link py-4 active" id="regArea-tab" data-bs-toggle="tab"
                                                                        data-bs-target="#regArea" type="button" role="tab"
                                                                        aria-controls="regArea" aria-selected="false">
                                                                        Sign Up
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="reg-content regMod">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={onSubmitRegister} action="#" className="p-4 p-sm-8">
                                                                        <p>Sign up for faster checkout, special offers, and customer
                                                                            support!</p>
                                                                        <div className="form-inside mt-6 p-3 p-sm-6 pb-0">
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="fname">First Name*</label>
                                                                                        <input type="text" name='fname' id="fname" placeholder="Enter Your First Name"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="lname">Last Name*</label>
                                                                                        <input type="text" id="lname" name='lname'
                                                                                            placeholder="Enter Your Last Name"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="email">Email*</label>
                                                                                        <input type="email" id="email" name='email'
                                                                                            placeholder="Enter your email"
                                                                                            autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="password">Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                            <input type={isRevealPwd ? "text" : "password"} id="password" name="password" placeholder="Enter your Password" autoComplete="off" required />
                                                                                            {isRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="cpassword">Confirm Password*</label>
                                                                                        <div className="pass-box d-center position-relative">
                                                                                            <input type={isConformRevealPwd ? "text" : "password"} id="cpassword" name="cpassword" placeholder="Enter your Password" autoComplete="off" required />
                                                                                            {isConformRevealPwd ? <i
                                                                                                className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsConformRevealPwd(prevState => !prevState)}>
                                                                                                visibility </i> : <i
                                                                                                    className="material-symbols-outlined show-hide-pass position-absolute end-0 pe-4" onClick={() => setIsConformRevealPwd(prevState => !prevState)}>
                                                                                                visibility_off</i>}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="checkbox-item d-grid gap-4 mt-4 mt-sm-6">
                                                                                        <label
                                                                                            className="single-radio checkbox position-relative d-flex align-items-center px-5 ps-8 justify-content-between">
                                                                                            <span className="text-start d-flex gap-1">
                                                                                                I agree to the <a href='/terms&condition' style={{ color: '#dff00e', fontWeight: "600" }} target='_blank'>Terms of
                                                                                                    Services</a>
                                                                                            </span>
                                                                                            <span className="flipbutton" onClick={handleflip} id="registerButton" >
                                                                                                Back to login →
                                                                                            </span>
                                                                                            <input type="checkbox" name="agree" onChange={termsService} />
                                                                                            <span className="checkmark start-0"></span>
                                                                                        </label>
                                                                                    </div>
                                                                                    <div className="btn-area mt-4 mt-sm-8">
                                                                                        {
                                                                                            isCheck === true ?
                                                                                                <button className="box-style btn-box">
                                                                                                    Sign Up
                                                                                                </button> :
                                                                                                <button className="box-style btn-box" disabled>
                                                                                                    Sign Up
                                                                                                </button>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </ReactCardFlip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>


                <Modal
                    isOpen={show}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeforget}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closeforget}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Forget Password
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={ForgetSubmit} id="formdata" action="#" className="p-4 p-sm-8">
                                                                    <p>Sign up for faster checkout, special offers, and customer
                                                                        support!</p>
                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Email*</label>
                                                                                    <input type="email" id="loginemail" name="email"
                                                                                        placeholder="Enter your email"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Send mail
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                <Modal
                    isOpen={FinalModal}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeforgetfinalmodal}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closeforgetfinalmodal}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Enter a Password
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={FinalForgetSubmite} id="formdata" action="#" className="p-4 p-sm-8">
                                                                    <p>Sign up for faster checkout, special offers, and customer
                                                                        support!</p>
                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">New Password</label>
                                                                                    <input type="text" id="npassword" name="npassword"
                                                                                        placeholder="Enter your new password"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Confirm Password</label>
                                                                                    <input type="text" id="cpassword" name="cpassword"
                                                                                        placeholder="Enter your Confirm Password"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Save
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                {editProfile && (
                    <Modal
                        isOpen={editProfile}
                        onAfterOpen={afterOpenModal}
                        onRequestClose={closeEditprofile}
                        style={customStyles}
                        contentLabel="Example Modal"
                    >
                        <div className="log-reg cmn-popup edit-profile">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="modal fade" id="loginMod" >
                                            <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                        <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                            aria-label="Close" onClick={closeEditprofile}>
                                                            <i className="material-symbols-outlined fs-two"> close </i>
                                                        </button>
                                                    </div>
                                                    <div className="tab-content flipper" id="flipper">
                                                        <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                            aria-labelledby="loginArea-tab">
                                                            <div className="login-content">
                                                                <div className="modal-body p-0">
                                                                    <form onSubmit={ProfileDataSubmite} id="formdata" action="#" className="p-4 p-sm-8">
                                                                        <div className="avatar-upload">
                                                                            <div {...getRootProps1()} className="dropzone avatar-edit">
                                                                                <input {...getInputProps1()} type='file' id="" />
                                                                                <label htmlFor="imageUpload"></label>
                                                                                <p>Drag & drop files here, or click to select files</p>

                                                                            </div>
                                                                            <div className="avatar-preview">
                                                                                <div id="imagePreview" style={{ backgroundImage: `url(${profilePicture ? profilePicture : data.data.profile})`, backgroundSize: "cover" }}>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-inside p-3 p-sm-6" style={{ marginTop: "-100px" }}>
                                                                            <div className="row">
                                                                                <div className="col-sm-12">
                                                                                    <input type="text" id="id" name="id" defaultValue={data.data._id} placeholder="Enter your First Name" autoComplete="off" hidden />
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">First Name</label>
                                                                                        <input type="text" id="fname" name="fname" defaultValue={data.data.fname} placeholder="Enter your First Name" autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="single-input text-start">
                                                                                        <label htmlFor="loginemail">Last Name</label>
                                                                                        <input type="text" id="lname" name="lname" defaultValue={data.data.lname} placeholder="Enter your Last Name" autoComplete="off" required />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-sm-12">
                                                                                    <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                        <button className="box-style btn-box">
                                                                                            Save
                                                                                        </button>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </Modal>
                )}

                <Modal
                    isOpen={support}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closesupport}
                    style={customStyles}
                    contentLabel="Example Modal"
                >
                    <div className="log-reg cmn-popup">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="modal fade" id="loginMod" >
                                        <div className="modal-dialog pt-8 pt-sm-0 modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className="modal-header border-0 p-0 justify-content-between position-absolute">
                                                    <button type="button" id='closmodal' className="btn-close p-0" data-bs-dismiss="modal"
                                                        aria-label="Close" onClick={closesupport}>
                                                        <i className="material-symbols-outlined fs-two"> close </i>
                                                    </button>
                                                </div>
                                                <div className="tab-content flipper" id="flipper">

                                                    <div className="tab-pane fade show active front" id="loginArea" role="tabpanel"
                                                        aria-labelledby="loginArea-tab">
                                                        <ul className="nav flex-nowrap log-reg-btn justify-content-center">
                                                            <li className="bottom-area" role="presentation">
                                                                <button className="nav-link py-4 active" id="loginArea-tab" data-bs-toggle="tab"
                                                                    data-bs-target="#loginArea" type="button" role="tab"
                                                                    aria-controls="loginArea" aria-selected="true">
                                                                    Support
                                                                </button>
                                                            </li>
                                                        </ul>
                                                        <div className="login-content">
                                                            <div className="modal-body p-0">
                                                                <form onSubmit={supportSubmit} id="formdata" action="#" className="p-4 p-sm-8">

                                                                    <div className="form-inside mt-6 p-3 p-sm-6">
                                                                        <div className="row">
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Email</label>
                                                                                    <input type="text" id="email" name="email"
                                                                                        placeholder="Enter your Email"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Subject</label>
                                                                                    <input type="text" id="subject" name="subject"
                                                                                        placeholder="Enter your Subject"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="single-input text-start">
                                                                                    <label htmlFor="loginemail">Game Name</label>
                                                                                    <input type="text" id="gname" name="gname"
                                                                                        placeholder="Enter your Game Name"
                                                                                        autoComplete="off" required />
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="avatar-uploads dropzones">
                                                                                    <div {...getRootProps2()} className="dropzones avatar-edit">
                                                                                        <input {...getInputProps2()} type='file' id="" />
                                                                                        <label htmlFor="imageUpload"></label>
                                                                                        <p>Drag & drop files here, or click to select files</p>
                                                                                    </div>
                                                                                    <div className="avatar-preview">
                                                                                        <div id="imagePreview">
                                                                                            <img src={supportPicture ? supportPicture : ''} width={150} />
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="btn-area d-flex mt-4 mt-sm-8 mb-4 justify-content-between">
                                                                                    <button className="box-style btn-box">
                                                                                        Submit
                                                                                    </button>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </Modal>

                <div className="preloader align-items-center justify-content-center">
                    <span className="loader"></span>
                </div>
                <button className="scrollToTop d-md-flex d-center" aria-label="scroll Bar Button">Back To Top</button>
                <div className="follower"></div>
                <header className="header-section header-menu">
                    <nav className="navbar w-100 flex-nowrap px-2 py-4 ps-2 ps-xl-0 navbar-expand-xl">
                        <div className="sidebar-close mobile-menu">
                            <button className="d-center d-grid d-xl-none">
                                <i className="material-symbols-outlined mat-icon fs-four"> menu_open </i>
                                <span className="fs-six">MENU</span>
                            </button>
                        </div>
                        <a href="/" className="navbar-brand ms-4 ms-xxl-20 d-flex align-items-center gap-2">
                            {/* <img src="assets/images/fav.png" className="logo" alt="logo" /> */}
                            <img src="assets/images/throw_cards.png" className="logo-text d-xxl-block d-none" alt="logo-text" />
                        </a>
                        <div className="collapse navbar-collapse d-flex gap-10 w-100 justify-content-end" id="navbar-content">
                            {/* <div className="contact-info ms-xl-0 ms-xxl-5 d-none d-sm-flex align-items-center gap-2">
                                <i className="material-symbols-outlined mat-icon"> smartphone </i>
                                <span>(302) 555-0107</span>
                            </div> */}
                            <ul className="navbar-nav d-xl-flex d-none gap-6 py-4 py-lg-0 m-auto p-20 align-self-center">
                                <li className={activeItem === 'home' ? 'active scrollToTop Tops' : 'scrollToTop active Tops'}>
                                    <a onClick={() => handleItemClick('home')} className='dropdown-nav' href={"/"}>Home</a>
                                </li>
                                <li className={activeItem === 'stosre' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('store')} className="dropdown-nav" href={"/store"}>Store</a>
                                </li>
                                <li className={activeItem === 'about' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('about')} className="dropdown-nav" href="/about">About</a>
                                </li>
                                <li className={activeItem === 'contact' ? 'active' : ''}>
                                    <a onClick={() => handleItemClick('contact')} className="dropdown-nav" href="#contact">Contact</a>
                                </li>
                            </ul>
                            <div className="right-area position-relative d-flex gap-3 gap-xxl-6 align-items-center">
                                {/* <div className="single-item">
                                    <div className="cmn-head">
                                        <div className="icon-area d-center position-relative">
                                            <i className="material-symbols-outlined mat-icon fs-five">search</i>
                                        </div>
                                    </div>
                                    <div className="main-area p-5">
                                        <h5 className="mb-2">Search</h5>
                                        <form action="#">
                                            <div className="input-area mt-6 p-4 d-flex align-items-center">
                                                <input type="text" placeholder="Enter Your Email" />
                                                <div className="btn-area">
                                                    <button className="box-style btn-box border-re py-1 p-2">
                                                        Search
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div> */}
                                <div className="cmn-head" style={{ cursor: "inherit" }}>
                                    {data.error === false && data.isloggedIn === true ? <span>
                                        <img src='https://throwcards.io/Coins/01.png' className='me-1' width={24} height={22} alt='img not found' />
                                        {Math.round(coins.chips).toLocaleString() ? Math.round(coins.chips).toLocaleString() : 0}</span> : ''}
                                </div>
                                <div className="cmn-head">
                                    <Tooltip title="Profile" arrow>
                                        <button type="button" className="icon-area d-center position-relative" id='showmodal' data-bs-toggle="modal"
                                            data-bs-target="#loginMod" onClick={openModal}>
                                            <i className="material-symbols-outlined mat-icon fs-five">person</i>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div className="cmn-head">
                                    {data.error === false && data.isloggedIn === true ?
                                        <Tooltip title="LogOut" arrow>
                                            <button type="button" className="icon-area d-center position-relative" id='showmodal' data-tip="This is a tooltip" data-width="200" onClick={logout}>
                                                <i className="material-symbols-outlined mat-icon fs-five">Logout</i>
                                            </button>
                                        </Tooltip>
                                        : ''}
                                </div>
                                {isSidebar && (
                                    <div className="single-item cart-area active">
                                        <div className="nav-items-wrapper d-flex flex-column p-4 p-sm-7 justify-content-between"  >
                                            <button className="position-absolute close-btn">
                                                <i className="material-symbols-outlined mat-icon fs-three" onClick={closeSidebar}>close</i>
                                            </button>
                                            <div className="nav-items ">
                                                <h4 className="mb-7">Your Profiles</h4>
                                                <div className="single-box p-2">
                                                    <div className="d-flex gap-2 justify-content-between align-items-start">
                                                        <div className="content-box d-flex">
                                                            <div className="icon-box d-inline-flex d-center" style={{ border: "4px solid #dff00e", borderRadius: "50px" }}>
                                                                <img src={data.data.profile} alt="icon" />
                                                            </div>
                                                            <div className="info-box">
                                                                <a href="#!">
                                                                    <h5>{data.data.fname} {data.data.lname}</h5>
                                                                </a>
                                                                <span>
                                                                    <img src='https://throwcards.io/Coins/01.png' className='me-1' width={24} height={22} alt='img not found' />
                                                                    {Math.round(coins.chips).toLocaleString() ? Math.round(coins.chips).toLocaleString() : 0}</span>
                                                                <a href='#!'>
                                                                    <p>{data.data.email}</p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <button type="button" className="end-area">
                                                            <i className="material-symbols-outlined pe-3 fs-six" onClick={handleEdit}>edit</i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </nav>
                </header>
                <div className="sidebar-wrapper">
                    <div className="position-relative">
                        {/* <div className="side-menubar py-6 d-flex flex-column justify-content-between align-items-center">
                            <div className="sidebar-close d-none d-xl-block">
                                <button className="d-center d-grid">
                                    <i className="material-symbols-outlined mat-icon fs-three"> menu_open </i>
                                    <span>MENU</span>
                                </button>
                            </div>
                            <ul className="d-grid gap-4 social-area mt-10">
                                <li>
                                    <a href="https://www.facebook.com/" aria-label="Facebook" className="d-center">
                                        <i className="fab fa-facebook-f"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.instagram.com/" aria-label="Instagram" className="d-center">
                                        <i className="fab fa-instagram"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/" aria-label="Linkedin" className="d-center">
                                        <i className="fab fa-linkedin-in"></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" aria-label="Twitter" className="d-center">
                                        <i className="fab fa-twitter"></i>
                                    </a>
                                </li>
                            </ul>
                            <ul className="d-grid gap-4 social-area mt-10"></ul>
                        </div> */}
                        <div className="sidebar-content d-center flex-columnn">
                            <div className="header-section d-block">
                                <div className="navbar bg-transparent">
                                    <ul className="navbar-nav d-xl-flex gap-2 gap-md-5 py-4 py-lg-0 px-4 px-lg-0 align-self-center mobile-dev">
                                        <li className={activeItem === 'home' ? 'active scrollToTop Tops' : 'scrollToTop active Tops'}>
                                            <a onClick={() => handleItemClick('home')} style={{ fontSize: "22px" }} className="dropdown-nav" href={"/"}>Home</a>
                                        </li>
                                        <li className={activeItem === 'store' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('store')} style={{ fontSize: "22px" }} className="dropdown-nav" href={"/store"}>Store</a>
                                        </li>
                                        <li className={activeItem === 'about' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('about')} style={{ fontSize: "22px" }} className="dropdown-nav" href="/about">About</a>
                                        </li>
                                        <li className={activeItem === 'contact' ? 'active' : ''}>
                                            <a onClick={() => handleItemClick('contact')} style={{ fontSize: "22px" }} className="dropdown-nav" href="#contact">Contact</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="footer-items w-100 bottom-0">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Office</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> location_on </i>
                                                </div>
                                                <div className="right-item w-75">
                                                    <h5>London</h5>
                                                    <span>Al. Brucknera Aleksandra 63, Wrocław 51-410</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Email address</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> mail </i>
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="65201d0408150900250208040c094b060a08">[email&#160;protected]</a></span>
                                                    <span><a href="https://pixner.net/cdn-cgi/l/email-protection" className="__cf_email__" data-cfemail="793c01181409151c391e14181015571a1614">[email&#160;protected]</a></span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Phone Number</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> call </i>
                                                </div>
                                                <div className="right-item d-grid">
                                                    <span>(302) 555-0107</span>
                                                    <span>(302) 555-0107</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-lg-6">
                                        <div className="single-item p-5 py-xxl-10 px-xxl-8">
                                            <h4 className="mb-6">Working Hours</h4>
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="icon-box d-center">
                                                    <i className="material-symbols-outlined mat-icon fs-fure"> schedule </i>
                                                </div>
                                                <div className="right-item w-50">
                                                    <span>Mon-Fri: 09: 00-18: 00 Sat-Sun: Weekend</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="banner-section index-one overflow-hidden">
                    <div className="shape-area">
                        <img src="assets/images/abs-items/planet.png" className="shape-1" alt="icon" />
                        <img src="assets/images/abs-items/console-01.png" className="shape-2" alt="icon" />
                        <img src="assets/images/abs-items/console-02.png" className="shape-3" alt="icon" />
                    </div>
                    <div className="ellipse-area ellipse-one position-absolute">
                        <img src="assets/images/abs-items/ellipse-6.png" className="ellipse-1" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-4.png" className="ellipse-2" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-5.png" className="ellipse-3" alt="icon" />
                    </div>
                    <div className="ellipse-area ellipse-two position-absolute">
                        <img src="assets/images/abs-items/ellipse-6.png" className="ellipse-1" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-3.png" className="ellipse-3" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-4.png" className="ellipse-4" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-1.png" className="ellipse-2" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-5.png" className="ellipse-5" alt="icon" />
                    </div>
                    <div className="ellipse-area ellipse-three position-absolute">
                        <img src="assets/images/abs-items/ellipse-6.png" className="ellipse-1" alt="icon" />
                    </div>
                    <div className="ellipse-area ellipse-four position-absolute">
                        <img src="assets/images/abs-items/ellipse-6.png" className="ellipse-1" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-3.png" className="ellipse-2" alt="icon" />
                        <img src="assets/images/abs-items/ellipse-2.png" className="ellipse-3" alt="icon" />
                    </div>
                    <div className="overlay overflow-hidden">
                        <div className="banner-content position-relative">
                            <div className="container">
                                <div className="row justify-content-between justify-content-center align-items-center justify-content-sm-space-evenly">
                                    <div className="col-xl-7 col-lg-7">
                                        <div className="main-content">
                                            <div className="mb-8">
                                                <h3 className="visible-slowly-bottom sub-title"><span>Building Gaming Worlds</span></h3>
                                                <span className="display-one">We Craft Games
                                                    <br />
                                                    <Typewriter className="typed d-inline-block" options={{ strings: ['Entertainment', 'Games', 'Joy'], autoStart: true, loop: true, delay: 50, deleteSpeed: 20 }} />
                                                </span>
                                                <p className="fs-four">Developing games that are imaginative, fun and bringing
                                                    colors to the gaming world</p>
                                            </div>
                                            <div className="btn-area alt-bg">
                                                <a href="#tab" className="box-style btn-box d-center">
                                                    Explore Our Games
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-4 col-lg-5 col-md-7 col-sm-9 mt-10 mt-lg-0 col-10">
                                        <div className="game-carousel pb-20">
                                            <Slider ref={setSliderRef} {...sliderSettings}>
                                                <div className="slide-area">
                                                    <div className="single-slider p-3 p-sm-5">
                                                        <div className="thumb-wrapper">
                                                            <div className="thumb">
                                                                <img src="assets/images/feature_images/Slider_Image/Bhabhi_card_game.png" alt="Image" />
                                                            </div>
                                                            <div className="app-download-title pt-5 pb-3 text-center">
                                                                <h3>Bhabhi Card Game</h3>
                                                            </div>
                                                            <div className="app-download d-flex gap-5 align-items-center">
                                                                <a href="https://apps.apple.com/us/app/bhabhi-card-game/id1568838952" target='_blank'><img
                                                                    src="assets/images/appstore.png" alt="Image" /></a>
                                                                <a href="https://play.google.com/store/apps/details?id=com.oengines.bhabhioe" target='_blank'><img
                                                                    src="assets/images/googleplay.png" alt="Image" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="slide-area">
                                                    <div className="single-slider p-3 p-sm-5">
                                                        <div className="thumb-wrapper">
                                                            <div className="thumb">
                                                                <img src="assets/images/feature_images/Slider_Image/Kachuful_offline.png" alt="Image" />
                                                            </div>
                                                            <div className="app-download-title pt-5 pb-3 text-center">
                                                                <h3>Kachuful offline</h3>
                                                            </div>
                                                            <div className="app-download d-flex gap-5 align-items-center">
                                                                <a href=" https://apps.apple.com/us/app/kachuful-judgement-card-game/id1580540560" target='_blank'><img
                                                                    src="assets/images/appstore.png" alt="Image" /></a>
                                                                <a href="https://play.google.com/store/apps/details?id=com.oengines.kachuful.judgement" target='_blank'><img
                                                                    src="assets/images/googleplay.png" alt="Image" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="slide-area">
                                                    <div className="single-slider p-3 p-sm-5">
                                                        <div className="thumb-wrapper">
                                                            <div className="thumb">
                                                                <img src="assets/images/feature_images/Slider_Image/Rummy_500_Offline.png" alt="Image" />
                                                            </div>
                                                            <div className="app-download-title pt-5 pb-3 text-center">
                                                                <h3>Rummy 500 Game</h3>
                                                            </div>
                                                            <div className="app-download d-flex gap-5 align-items-center">
                                                                <a href="https://apps.apple.com/us/app/rummy-500-cards/id1620553752" target='_blank'><img
                                                                    src="assets/images/appstore.png" alt="Image" /></a>
                                                                <a href="https://play.google.com/store/apps/details?id=com.oengines.rummy500cards" target='_blank'><img
                                                                    src="assets/images/googleplay.png" alt="Image" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="slide-area">
                                                    <div className="single-slider p-3 p-sm-5">
                                                        <div className="thumb-wrapper">
                                                            <div className="thumb">
                                                                <img src="assets/images/feature_images/Slider_Image/tonk_the_card_game.png" alt="Image" />
                                                            </div>
                                                            <div className="app-download-title pt-5 pb-3 text-center">
                                                                <h3>Tank Card Game</h3>
                                                            </div>
                                                            <div className="app-download d-flex gap-5 align-items-center">
                                                                <a href="https://apps.apple.com/us/app/tonk-the-card-game/id1566836820" target='_blank'><img
                                                                    src="assets/images/appstore.png" alt="Image" /></a>
                                                                <a href="https://play.google.com/store/apps/details?id=com.oengines.tonkoffline" target='_blank'><img
                                                                    src="assets/images/googleplay.png" alt="Image" /></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="about-block pt-120">
                    <div className="container">
                        <div className="row justify-content-between">
                            <div className="col-xxl-6 col-lg-6 order-1 order-lg-0 text-white">
                                {/* <div className="sec-img mw-100 position-relative d-center">
                                    <img src="assets/images/about-block-bg.png" alt="sec-img" />
                                    <div className="experience p-2 p-lg-4 position-absolute ">
                                        <div className="experience-wrap p-3 px-lg-5 py-lg-8 d-inline-flex d-center gap-3">
                                            <div className="counters d-center">
                                                <span className="odometer display-four" data-odometer-final="20">0</span>
                                                <span className="display-four visible-slowly-bottom symbol">+</span>
                                            </div>
                                            <span className="w-25 d-inline-bloc">Years Of Experience</span>
                                        </div>
                                    </div>
                                </div> */}
                                <table>
                                    <thead>
                                        <tr className='row m-0'>
                                            <th className='col-md-1 th-heading'>Rank</th>
                                            <th className='col-md-5 th-heading' colSpan={2}>Players</th>
                                            <th className='col-md-3 th-heading'>Total Game Played</th>
                                            <th className='col-md-3 th-heading'>Total Game Won</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {users.map((user, index) => (
                                            <tr className="clickable row m-0">
                                                <td className='col-md-1 p-0'>
                                                    <span> <RankComponent rank={index + 1} /></span>
                                                    <p style={{ marginTop: "-11px", fontSize: "13px" }}>Rank</p>
                                                </td>
                                                <td className='profile-img col-md-5 text-start'>
                                                    <img src={user.profile} alt='profile-img' />
                                                    <span>{user.fname} {user.lname}</span>
                                                </td>
                                                <td className='col-md-3'>
                                                    {user.userStatistic.totalGamePlay}
                                                </td>
                                                <td className='col-md-3'>
                                                    {user.userStatistic.totalGameWon}
                                                </td>

                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                            <div className="col-xxl-5 col-lg-6">
                                <div className="section-text">
                                    <h4 className="sub-title">Welcome to <span>Throwcards</span> Game Studios</h4>
                                    <span className="fs-two heading mb-6">Bringing people together through <span>the power of
                                        play</span></span>
                                    <p>As Game Throwcards, we continue to open doors to new worlds every day and we are
                                        working excitedly for creating new games and unique ideas!</p>
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="counter-item mb-8 pb-4">
                                            <div className="counters mb-3 d-flex align-items-center">
                                                <span className="odometer fs-three heading one" data-odometer-final={download}>0</span>
                                                <span className="fs-three heading">M</span>
                                                <span className="fs-three heading symbol">+</span>
                                            </div>
                                            <span className="name-area fs-seven">
                                                Downloads, or 6% of the world’s population.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item mb-8 pb-4">
                                            <div className="counters mb-3 d-flex align-items-center">
                                                <span className="odometer fs-three heading two" data-odometer-final={gameUser}>0</span>
                                                <span className="fs-three heading">M</span>
                                                <span className="fs-three heading symbol">+</span>
                                            </div>
                                            <span className="name-area fs-seven">
                                                Our games have over 20 million unique daily users.
                                            </span>
                                        </div>
                                    </div>

                                    <div className="col-sm-6">
                                        <div className="counter-item mb-8 pb-4">
                                            <div className="counters mb-3 d-flex align-items-center">
                                                <span className="odometer fs-three heading three" data-odometer-final={experts}>0</span>
                                                <span className="fs-three heading symbol">+</span>
                                            </div>
                                            <span className="name-area fs-seven">
                                                Experts collaborating to blow your mind in one place.
                                            </span>
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="counter-item mb-8 pb-4">
                                            <div className="counters mb-3 d-flex align-items-center">
                                                <span className="odometer fs-three headings four" data-odometer-final={launchCount}>0</span>
                                                <span className="fs-three heading">k</span>
                                                <span className="fs-three heading symbol">+</span>
                                            </div>
                                            <span className="name-area fs-seven">
                                                The Throwcards Studio is launching with 4K+ Games.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="our-games overflow-hidden pt-120 pb-120" id='tab'>
                    <div className="container singletab">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-text text-center">
                                    <h4 className="sub-title">Crafting Unforgettable <span>Gaming Experiences</span></h4>
                                    <span className="fs-two heading mb-6">Our Games are <span>Vibrant</span> Worlds With <span>Charming</span> Cards</span>
                                    <p>Our projects feature unique mechanics, fine-tuned game play, and eye-catching visual
                                        style.Over 100 million people play our games every month</p>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-6 text-center">
                                <ul className="nav tablinks flex-wrap d-center mb-6 mb-sm-10 d-inline-flex gap-4 p-3 tab-area">
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0 active">
                                            <span className="icon-area">
                                                <i className="ri-macbook-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>
                                    {/* <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-apple-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-google-play-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-amazon-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-windows-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>   
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-steam-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li>
                                    <li className="nav-item">
                                        <button className="nav-link d-center box-style btn-box p-0">
                                            <span className="icon-area">
                                                <i className="ri-app-store-line fs-two"></i>
                                            </span>
                                        </button>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-lg-10">
                                <div className="tabcontents">
                                    <div className="tabitem active">
                                        <div className="cus-mar">
                                            <ReactCardFlip isFlipped={isFlipped2} flipDirection="horizontal">
                                                <div className="single-box position-relative p-3 p-md-4">
                                                    <span className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                    <div className="row align-items-center">
                                                        <div className="col-md-5">
                                                            <div className="position-relative d-center">
                                                                <img src="assets/images/feature_images/Game_Img/Bhabhi_card_game.png" className="w-100" alt="sec-img" />
                                                                <a className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute img-chng" onClick={handleClick}>
                                                                    <p>
                                                                        Play
                                                                    </p>
                                                                </a>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-7 mt-6 mt-md-0">
                                                            <a href="#!">
                                                                <h3 className="visible-slowly-bottom mb-4" name="gname">Bhabhi Card Game</h3>
                                                            </a>
                                                            <ul
                                                                className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                                <li>Trick-Tacking</li>
                                                            </ul>
                                                            <p>The player who holds the Ace of Spades begins by playing it face up on the table, and each of the other players must also play a card face up. Each trick is begun by the player of the highest card in the suit that was led to the previous trick: this player is said to "have the power.</p>
                                                            <div className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                                <div className="single-area">
                                                                    <img src="assets/images/gameIcon/logo-256.png" width={100} alt="image" />
                                                                </div>
                                                                <div className="single-area">
                                                                    <div className="d-flex gap-1 align-items-center mb-1">
                                                                        <i className="material-symbols-outlined mat-icon">star</i>
                                                                        <h4 className="fs-four">4.5</h4>
                                                                    </div>
                                                                    <p className="fs-seven">5.2M Reviews</p>
                                                                </div>
                                                                <div className="single-area">
                                                                    <h4 className="fs-four mb-1">{download}M+</h4>
                                                                    <p className="fs-seven">Downloads</p>
                                                                </div>
                                                            </div>
                                                            <div className="app-download d-flex gap-20 align-items-center">
                                                                <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                    src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                                <a href="https://play.google.com/" className="w-100"><img
                                                                    src="assets/images/googleplay.png" className="w-100"
                                                                    alt="Image" /></a>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='single-box position-relative p-md-4 text-center'>
                                                    <div className='run-games'></div>
                                                    <a className="box-style btn-box d-center text-center" style={{ color: "#342225" }} onClick={returenFlipped}>Exit</a>
                                                </div>
                                            </ReactCardFlip>

                                            {/* <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/bluff_card_game.png" className="w-100" alt="sec-img" />
                                                            <a href="#!"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                <p>
                                                                    Play
                                                                </p>
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Bluff Card Game</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Trick-Tacking</li>
                                                        </ul>
                                                        <p>The best one player card game kachuful card game is now ready for android mobile phones and tablets with its high quality. Download now and play free. You can play kachuful offline in anywhere you want. The main contribution of our game kachuful is playing offline kachuful its challenging opponents with Strong AI, classy graphics and smooth gameplay.</p>
                                                        <div className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" width={100} alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">{download}M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-20 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/store/apps/details?id=com.oengines.kachuful.judgement&pcampaignid=web_share" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/Durak_offline.png" className="w-100" alt="sec-img" />
                                                            <a href="http://localhost:3500/bgame"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Durak offline</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">                                                <span
                                                className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/gin_rummy_Offline.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Gin Rummy offline</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/Kachuful_offline.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Kachuful Offline</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/Rummy_500_Offline.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Rummy 500 Offline</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/feature_images/Game_Img/tonk_the_card_game.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Tonk Card Game</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </div>
                                    </div>
                                    {/* <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tabitem">
                                        <div className="cus-mar">
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-5.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Cyber Crusade</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-1.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Upgrade Your Weapon</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-3.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Shadow Quest</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="single-box position-relative p-3 p-md-4">
                                                <span
                                                    className="feature-tag d-flex d-md-none d-xl-flex d-center position-absolute">Feature</span>
                                                <div className="row align-items-center">
                                                    <div className="col-md-5">
                                                        <div className="position-relative d-center">
                                                            <img src="assets/images/game-image-4.png" className="w-100" alt="sec-img" />
                                                            <a href="https://www.youtube.com/watch?v=IaT4DneyKLc"
                                                                className="box-style btn-box-second heading-five fs-five mfp-iframe popupvideo text-uppercase d-center position-absolute">
                                                                Play
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-7 mt-6 mt-md-0">
                                                        <a href="#!">
                                                            <h3 className="visible-slowly-bottom mb-4">Galactic Escape</h3>
                                                        </a>
                                                        <ul
                                                            className="d-flex flex-wrap mb-6 fs-seven align-items-center gap-5 gap-md-10">
                                                            <li>Horror Adventure</li>
                                                            <li>Mobile</li>
                                                            <li>Action RPG</li>
                                                            <li>PC</li>
                                                        </ul>
                                                        <p>Mauris euismod nibh mollis sodales eleifend. Donec tempor erat
                                                            risus, nec congue magna accumsan commodo pretium..</p>
                                                        <div
                                                            className="review-box mt-5 mt-md-8 mb-6 mb-md-10 w-100 p-2 p-sm-4 d-center gap-3 justify-content-between">
                                                            <div className="single-area">
                                                                <img src="assets/images/review-img.png" alt="image" />
                                                            </div>
                                                            <div className="single-area">
                                                                <div className="d-flex gap-1 align-items-center mb-1">
                                                                    <i className="material-symbols-outlined mat-icon">star</i>
                                                                    <h4 className="fs-four">4.5</h4>
                                                                </div>
                                                                <p className="fs-seven">5.2M Reviews</p>
                                                            </div>
                                                            <div className="single-area">
                                                                <h4 className="fs-four mb-1">500M+</h4>
                                                                <p className="fs-seven">Downloads</p>
                                                            </div>
                                                        </div>
                                                        <div className="app-download d-flex gap-4 align-items-center">
                                                            <a href="https://www.apple.com/app-store/" className="w-100"><img
                                                                src="assets/images/appstore.png" className="w-100" alt="Image" /></a>
                                                            <a href="https://play.google.com/" className="w-100"><img
                                                                src="assets/images/googleplay.png" className="w-100"
                                                                alt="Image" /></a>
                                                            <a href="https://www.amazon.com/" className="w-100"><img
                                                                src="assets/images/amazonstore.png" className="w-100"
                                                                alt="Image" /></a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  */}
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <section className="team-members pt-120">
                    <div className="container">
                        <div className="row justify-content-    center">
                            <div className="col-lg-6">
                                <div className="section-header text-center">
                                    <h4 className="sub-title">We Have A Passion For <span>Games!</span></h4>
                                    <span className="fs-two heading mb-6">Our team is next-level. And we're <span>proud of
                                        it.</span></span>
                                    <p>Our dynamic team blends tech-savvy developers, imaginative artists, analytical minds,
                                        and visionary product managers. Driven to innovate, we bring excitement to every
                                        project</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="team-carousel">
                            <Slider ref={setSliderRef} {...slidertwo}>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-1.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Jane Cooper</a></h4>
                                            <span className="designation">Animator</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-2.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Esther Howard</a></h4>
                                            <span className="designation">Artist</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-3.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Dianne Russell</a></h4>
                                            <span className="designation">Animator</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-4.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Marvin McKinney</a></h4>
                                            <span className="designation">Brand & Culture Manager</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-5.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Kristin Watson</a></h4>
                                            <span className="designation">Developer</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider">
                                        <div className="thumb">
                                            <img src="assets/images/team-members-6.png" alt="Image" />
                                        </div>
                                        <div className="title-area">
                                            <h4 className="pt-8 pb-2"><a href="#!">Bessie Cooper</a></h4>
                                            <span className="designation">Artist</span>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                <div className="counter-section pt-120">
                    <div className="container">
                        <div className="row cus-mar">
                            <div className="col-sm-6 col-xl-3">
                                <div className="single-box py-6 box-style box-first d-center position-relative">
                                    <div className="counters d-flex align-items-center">
                                        <span className="odometer display-one five" data-odometer-final="652">0</span>
                                        <span className="display-one symbol">+</span>
                                    </div>
                                    <p className="name-area fs-five position-absolute">
                                        Unique Daily Users.
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="single-box py-6 box-style box-first d-center active-area position-relative">
                                    <div className="counters d-flex align-items-center">
                                        <span className="odometer display-one six" data-odometer-final="50">0</span>
                                        <span className="display-one">M</span>
                                    </div>
                                    <p className="name-area fs-five position-absolute">
                                        Downloads
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="single-box py-6 box-style box-first d-center position-relative">
                                    <div className="counters d-flex align-items-center">
                                        <span className="odometer display-one seven" data-odometer-final="200">0</span>
                                        <span className="display-one symbol">+</span>
                                    </div>
                                    <p className="name-area fs-five position-absolute">
                                        Games Launched
                                    </p>
                                </div>
                            </div>
                            <div className="col-sm-6 col-xl-3">
                                <div className="single-box py-6 box-style box-first d-center position-relative">
                                    <div className="counters d-flex align-items-center">
                                        <span className="odometer display-one eight" data-odometer-final="350">0</span>
                                        <span className="display-one">k</span>
                                    </div>
                                    <p className="name-area fs-five position-absolute">
                                        Gaming Projects Delivered
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <section className="our-services pt-120 border-bottom-none">
                    <div className="container">
                        <div className="row align-items-center section-text">
                            <div className="col-lg-4">
                                <h4 className="sub-title">Our Team <span>Provide</span></h4>
                                <span className="fs-two heading">Next Level <span>Services</span></span>
                            </div>
                            <div className="col-lg-6">
                                <p>Throwcards Studios provides end-to-end services for clients wishing to employ game
                                    development businesses skilled in Unity3D, Unreal, PlayCanvas, blockchain, game design,
                                    VR, NFT, metaverse, and more.</p>
                            </div>
                        </div>
                        <div className="services-carousel">
                            <Slider ref={setSliderRef} {...sliderthree}>
                                <div className="slide-area">
                                    <div className="single-slider box-style box-first p-5 px-xl-8 py-xl-10">

                                        <div className="icon-box d-center">
                                            <i className="material-symbols-outlined fs-two"> sports_esports </i>
                                        </div>
                                        <div className="title-area">
                                            <h3 className="mt-6 mb-3">Game Development</h3>
                                            <p>Game design, programming, art, animation, audio, testing, marketing, publishing,
                                                monetization, maintenance, player support, analytics.</p>
                                            <div className="link-area mt-8">
                                                <a href="#!" className="d-flex align-items-center">
                                                    Learn More
                                                    <i className="material-symbols-outlined mat-icon">arrow_right_alt</i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider box-style box-first p-5 px-xl-8 py-xl-10">

                                        <div className="icon-box d-center">
                                            <i className="material-symbols-outlined fs-two"> draw </i>
                                        </div>
                                        <div className="title-area">
                                            <h3 className="mt-6 mb-3">Game Art & Design</h3>
                                            <p>Concept, character, environment, UI/UX, 2D/3D modeling, texturing, rigging,
                                                animation, VFX, lighting, compositing, motion graphics.</p>
                                            <div className="link-area mt-8">
                                                <a href="#!" className="d-flex align-items-center">
                                                    Learn More
                                                    <i className="material-symbols-outlined mat-icon">arrow_right_alt</i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider box-style box-first p-5 px-xl-8 py-xl-10">

                                        <div className="icon-box d-center">
                                            <i className="material-symbols-outlined fs-two">hub</i>
                                        </div>
                                        <div className="title-area">
                                            <h3 className="mt-6 mb-3">Nft Development</h3>
                                            <p>Smart contract development, NFT creation, blockchain integration,NFT games,
                                                digital ownership, NFT transfers, NFT authentication, NFT analytics.</p>
                                            <div className="link-area mt-8">
                                                <a href="#!" className="d-flex align-items-center">
                                                    Learn More
                                                    <i className="material-symbols-outlined mat-icon">arrow_right_alt</i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider box-style box-first p-5 px-xl-8 py-xl-10">

                                        <div className="icon-box d-center">
                                            <i className="material-symbols-outlined fs-two">home_max_dots</i>
                                        </div>
                                        <div className="title-area">
                                            <h3 className="mt-6 mb-3">Vr & Ar Solutions</h3>
                                            <p>VR, HMDs, mobile AR, spatial computing, gesture control, haptic feedback,
                                                360-degree audio, user tracking, immersive experiences.</p>
                                            <div className="link-area mt-8">
                                                <a href="#!" className="d-flex align-items-center">
                                                    Learn More
                                                    <i className="material-symbols-outlined mat-icon">arrow_right_alt</i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="slide-area">
                                    <div className="single-slider box-style box-first p-5 px-xl-8 py-xl-10">

                                        <div className="icon-box d-center">
                                            <i className="material-symbols-outlined fs-two"> sports_esports </i>
                                        </div>
                                        <div className="title-area">
                                            <h3 className="mt-6 mb-3">Game Development</h3>
                                            <p>Game design, programming, art, animation, audio, testing, marketing, publishing,
                                                monetization, maintenance, player support, analytics.</p>
                                            <div className="link-area mt-8">
                                                <a href="#!" className="d-flex align-items-center">
                                                    Learn More
                                                    <i className="material-symbols-outlined mat-icon">arrow_right_alt</i>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </section>
                <section className="life-Throwcards pt-120 pb-120 border-top-none">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-6">
                                <div className="section-text text-center">
                                    <h4 className="sub-title">We're Part of a <span>Big Family</span></h4>
                                    <span className="fs-two heading mb-6">The Art of Gaming Development: A Look <span>Inside Our
                                        Studio</span></span>
                                    <p>Experience the magic of gaming development with a look inside our studio. See the
                                        art, science, and innovation at work.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="post-img d-grid gap-3 gap-md-6 justify-content-between">
                                    <div className="single d-flex gap-3 gap-md-6">
                                        <div className="items">
                                            <img src="assets/images/life-post-img-1.png" alt="image" />
                                        </div>
                                        <div className="items">
                                            <img src="assets/images/life-post-img-2.png" alt="image" />
                                        </div>
                                    </div>
                                    <div className="single d-flex gap-3 gap-md-6">
                                        <div className="items">
                                            <img src="assets/images/life-post-img-3.png" alt="image" />
                                        </div>
                                        <div className="items">
                                            <img src="assets/images/life-post-img-4.png" alt="image" />
                                        </div>
                                        <div className="items">
                                            <img src="assets/images/life-post-img-5.png" alt="image" />
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center mt-10 mt-md-20">
                                    <div className="col-lg-5">
                                        <div className="join-us text-center">
                                            <span className="display-four visible-slowly-bottom mb-4">JOIN US!</span>
                                            <p>Still haven't found your dream job? Join Team Throwcards and become the next
                                                Throwcards of an ever-growing family!</p>
                                            <div className="btn-area alt-bg mt-5 mt-md-10">
                                                <a href="#!" className="box-style btn-box d-center">
                                                    Check Open Positions
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="job-opens pt-120 pb-120">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-7">
                                <div className="section-text text-center">
                                    <h4 className="sub-title">We're Looking for <span>Talented Professionals</span></h4>
                                    <span className="fs-two heading mb-6 w-75 m-auto"><span>Let's Build the Future</span> of
                                        Video Games Together!</span>
                                    <p>Our belief is that being simple, honest, self-driven and motivated combined with the
                                        constant pursuit of the ultimate company atmosphere can help grow a creative and
                                        robust team.</p>
                                </div>
                            </div>
                        </div>
                        <div className="row cus-mar">
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> draw </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>Senior Level Game Designer</h5>
                                            </a>
                                            <a href="#!"></a>
                                            <span className="fs-seven mt-2">Al. Brucknera Aleksandra63, Wrocław 51-410</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven p-1 px-2">Design</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> draw </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>Level Game Designer</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven p-1 px-2">Design</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> view_in_ar </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>SR UI/UX Game designer</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven p-1 px-2">Design</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> draw </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>Sr. Unity Developer</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven danger p-1 px-2">Developer</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> draw </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>Lead VFX Artist</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven info p-1 px-2">VFX Artist</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> view_in_ar </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>3D Artist</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven p-1 px-2">3D Artist</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> view_in_ar </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>SR. 3D Artist</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven p-1 px-2">3D Artist</span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div
                                    className="single-box flex-wrap box-style box-second p-3 p-md-6 d-flex gap-4 gap-md-6 justify-content-between">

                                    <div className="content-box d-flex flex-wrap gap-4 gap-md-6">
                                        <div className="icon-box d-inline-flex d-center">
                                            <i className="material-symbols-outlined fs-three"> manage_accounts </i>
                                        </div>
                                        <div className="info-box">
                                            <a href="#!">
                                                <h5>Product Manager</h5>
                                            </a>
                                            <span className="fs-seven mt-2">Al10</span>
                                            <ul className="d-flex gap-6 mt-6">
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> work </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                                <li className="d-flex align-items-center gap-2">
                                                    <i className="material-symbols-outlined mat-icon"> schedule </i>
                                                    <span className="fs-seven">Full Time</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="end-area">
                                        <span className="fs-seven success p-1 px-2">Manager</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="testimonials">
                    <div className="container pt-120">
                        <div className="row justify-content-end">
                            <div className="col-lg-7">
                                <div className="testimonials-carousel">
                                    <Slider ref={setSliderRef} {...sliderSettings}>
                                        <div className="slide-area">
                                            <div className="single-slider px-4 px-sm-8 pt-8 pb-15 pb-sm-20">
                                                <div className="img-box">
                                                    <img src="assets/images/testimonial-img-1.png" alt="Image" />
                                                </div>
                                                <ul className="list pt-4 pb-6 d-flex gap-1 align-items-center">
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                </ul>
                                                <p className="fs-four">“The graphics in their games are always top-notch and truly
                                                    bring the world to life”</p>
                                                <h5 className="pt-8 pb-3">Kristin Watson</h5>
                                                <div className="location-date d-flex gap-5">
                                                    <span className="location pe-5">United Kingdom</span>
                                                    <span>Mar 22, 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-area">
                                            <div className="single-slider px-4 px-sm-8 pt-8 pb-15 pb-sm-20">
                                                <div className="img-box">
                                                    <img src="assets/images/testimonial-img-2.png" alt="Image" />
                                                </div>
                                                <ul className="list pt-4 pb-6 d-flex gap-1 align-items-center">
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                </ul>
                                                <p className="fs-four">“The graphics in their games are always top-notch and truly
                                                    bring the world to life”</p>
                                                <h5 className="pt-8 pb-3">Jordyn Septimus</h5>
                                                <div className="location-date d-flex gap-5">
                                                    <span className="location pe-5">United Kingdom</span>
                                                    <span>May 12, 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-area">
                                            <div className="single-slider px-4 px-sm-8 pt-8 pb-15 pb-sm-20">
                                                <div className="img-box">
                                                    <img src="assets/images/testimonial-img-3.png" alt="Image" />
                                                </div>
                                                <ul className="list pt-4 pb-6 d-flex gap-1 align-items-center">
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                </ul>
                                                <p className="fs-four">“The graphics in their games are always top-notch and truly
                                                    bring the world to life”</p>
                                                <h5 className="pt-8 pb-3">Bessie Cooper</h5>
                                                <div className="location-date d-flex gap-5">
                                                    <span className="location pe-5">United Kingdom</span>
                                                    <span>Nov 25, 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="slide-area">
                                            <div className="single-slider px-4 px-sm-8 pt-8 pb-15 pb-sm-20">
                                                <div className="img-box">
                                                    <img src="assets/images/testimonial-img-4.png" alt="Image" />
                                                </div>
                                                <ul className="list pt-4 pb-6 d-flex gap-1 align-items-center">
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                    <li>
                                                        <i className="material-symbols-outlined mat-icon"> star </i>
                                                    </li>
                                                </ul>
                                                <p className="fs-four">“The graphics in their games are always top-notch and truly
                                                    bring the world to life”</p>
                                                <h5 className="pt-8 pb-3">Esther Howard</h5>
                                                <div className="location-date d-flex gap-5">
                                                    <span className="location pe-5">United Kingdom</span>
                                                    <span>Dec 17, 2023</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}

                <section className="call-to-action overflow-hidden border-none">
                    <div className="container pt-120 pb-120">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-5">
                                <div className="sec-img mt-0 mb-10 mb-sm-15 mb-lg-0 text-start d-center position-relative">
                                    <img src="assets/images/call-to-action-circle.png" alt="Image" />
                                    <div className="video-bg position-absolute">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="section-text">
                                    <h4 className="sub-title">Join Our <span>Community</span></h4>
                                    <span className="fs-two heading mb-6">Connect With <span>Gamers Worldwide</span></span>
                                    <p>Join the revolution and immerse yourself in the ultimate gaming experience, where the
                                        boundaries between reality and fantasy blur, and the only limit is your imagination.
                                        Sign up now and be the first to play our latest game releases.</p>
                                </div>
                                <div className="btn-area">
                                    <div className="btn-area alt-bg mt-10 d-flex flex-wrap gap-6 align-items-center">
                                        <a href="#!" id="contact" onClick={handleJoining} className="box-style btn-box btn-box-third d-center" style={{ backgroundColor: "#dff00e" }}>
                                            Join Now Our Community
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="contact-us pb-120 border-top-none" id='contact'>
                    <div className="container">
                        <div className="row justify-content-between align-items-center">
                            <div className="col-lg-5">
                                <div className="section-text">
                                    <h4 className="sub-title">Have <span>Questions?</span></h4>
                                    <span className="fs-two heading mb-6">We'd Love To <span>Hear From You</span></span>
                                    <p>Please fill out the form and let us know about your concerns.We will try our best to
                                        provide optimized solutions.</p>
                                </div>
                                <div className="contact-area">
                                    <div className="btn-area mt-10 d-grid gap-6 align-items-center">
                                        {/* <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-box d-center">
                                                <i className="material-symbols-outlined mat-icon fs-five"> call </i>
                                            </div>
                                            <span>+(2) 578 - 365 - 379</span>
                                        </div>
                                        <div className="d-flex gap-3 align-items-center">
                                            <div className="icon-box d-center">
                                                <i className="material-symbols-outlined mat-icon fs-five"> mail </i>
                                            </div>
                                           
                                        </div> */}
                                        {data.error === false ?
                                            <div className="d-flex gap-3 align-items-center">
                                                <div className="btn-area">
                                                    <button className="box-style btn-box" onClick={handleSupport}>
                                                        Support
                                                    </button>
                                                </div>
                                            </div>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-5 me-20 mt-7 mt-lg-0">
                                <form onSubmit={onSubmitContact} action="#" className="p-4">
                                    <div className="form-inside p-4">
                                        <div className="row">
                                            <div className="col-sm-12">
                                                <div className="single-input text-start">
                                                    <label htmlFor="contactName">Name</label>
                                                    <input type="text" id="contactName" name="contactName" placeholder="Enter Your Name"
                                                        autoComplete="off" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-input text-start">
                                                    <label htmlFor="contactEmail">Email</label>
                                                    <input type="email" id="contactEmail" name="contactEmail" placeholder="Enter your email"
                                                        autoComplete="off" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-input text-start">
                                                    <label htmlFor="contactSubject">Subject</label>
                                                    <input type="text" id="contactSubject" name="contactSubject" placeholder="Enter Subject"
                                                        autoComplete="off" required />
                                                </div>
                                            </div>
                                            <div className="col-sm-12">
                                                <div className="single-input text-start">
                                                    <label htmlFor="contactMessage">Leave us a message</label>
                                                    <textarea cols="4" rows="4" id="contactMessage" name="contactMessage"
                                                        placeholder="Please type your Message here..." required></textarea>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 mt-4">
                                                <div className="btn-area">
                                                    <button className="box-style btn-box">
                                                        Send Massage
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </section>
                {/* <CookieConsent
                    location="bottom"
                    buttonText="Accepted!"
                    declineButtonText="Reject!"                    
                    cookieName="myAwesomeCookieName2"
                    style={{ background: "rgb(59 43 44)", zIndex: "9999", textAlign: "center", fontFamily: "Chakra Petch" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px", background: "#dff00e" }}
                    declineButtonStyle={{ color: "#fff", fontSize: "13px", background: "#ff6347" }}
                    expires={150}
                    onClick={setCookiesData}
                    onDecline={() => {
                        // Handle the rejection of cookies here
                        
                    }}                
                >
                  
                </CookieConsent> */}
                <CookieConsent
                    location="bottom"
                    buttonText="Accept"
                    cookieName="cookieName"
                    enableDeclineButton
                    declineButtonText="Decline"
                    declineCookieValue={false}
                    style={{ background: "rgb(59 43 44)", zIndex: "9999", textAlign: "center", fontFamily: "Chakra Petch" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px", background: "#dff00e", fontWeight: "500" }}
                    declineButtonStyle={{ color: "#4e503b", fontSize: "13px", background: "#ff6347", fontWeight: "500" }}
                    expires={150}>
                    By clicking “Accept All Cookies”, you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.
                </CookieConsent>
                <footer className="footer-section">
                    <div className="container-fluid">
                        <div className="social-items">
                            <ul className="d-flex justify-content-around">
                                <li className="box-style box-second justify-content-center">
                                    <a href="https://www.facebook.com/" className="d-center gap-2">
                                        <i className="fab fa-facebook-f"></i>
                                        <span>Facebook</span>
                                    </a>
                                </li>
                                <li className="box-style box-second justify-content-center">
                                    <a href="https://www.instagram.com/" className="d-center gap-2">
                                        <i className="fab fa-instagram"></i>
                                        <span>Instagram</span>
                                    </a>
                                </li>
                                <li className="box-style box-second justify-content-center">
                                    <a href="https://www.linkedin.com/" className="d-center gap-2">
                                        <i className="fab fa-linkedin-in"></i>
                                        <span>linkedin</span>
                                    </a>
                                </li>
                                <li className="box-style box-second justify-content-center">
                                    <a href="https://twitter.com/" className="d-center gap-2">
                                        <i className="fab fa-twitter"></i>
                                        <span>Twitter</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="row align-items-center justify-content-center overflow-hidden">
                            <div className="col-lg-6 position-relative">
                                <div className="box-items d-inline-flex flex-wrap position-absolute">
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item active-item opacity-25"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item active-item opacity-25"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item active-item opacity-25"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                    <div className="single-item"></div>
                                </div>
                                <div
                                    className="row pt-120 pb-120 px-5 ps-md-15 ps-lg-0 align-items-center justify-content-start justify-content-lg-end border-top-none border-bottom-none">
                                    <div className="col-xl-8 col-lg-10 col-md-8">
                                        <div className="content-area visible-from-bottom">
                                            <div className="section-area">
                                                <a href="#!" className="fs-four brand-area">THROWCARDS</a>
                                                <p className="mt-4 w-75">Throwcards is a premier gaming studio creating
                                                    innovative and engaging games for players of all ages. Offering a wide
                                                    variety of gaming experiences.</p>
                                            </div>
                                            <div className="location-area mt-8 d-grid gap-7">
                                                {/* <div className="d-flex gap-3 align-items-center">
                                                    <div className="icon-box d-center">
                                                        <i className="material-symbols-outlined mat-icon fs-fure"> location_on
                                                        </i>
                                                    </div>
                                                    <div className="right-item">
                                                        <h5>London</h5>
                                                        <span>Al. Brucknera Aleksandra 63, Wrocław 51-410</span>
                                                    </div>
                                                </div> */}
                                                <div className="d-flex gap-3 align-items-center">
                                                    <div className="icon-box d-center">
                                                        <i className="material-symbols-outlined mat-icon fs-fure"> location_on
                                                        </i>
                                                    </div>
                                                    <div className="right-item">
                                                        <h5>India</h5>
                                                        <span>437, Amby vally arcade, Utran, Surat, Gujarat - 394105</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="content-right visible-from-bottom pt-120 pb-120 px-5 ps-md-15 ps-lg-10 px-xxl-20">
                                    <div className="row justify-content-md-center justify-content-lg-start justify-content-xl-center  justify-content-xxl-start">
                                        <div className="col-xl-8 col-lg-10 col-md-7">
                                            <div className="main-content">
                                                <div className="head-area">
                                                    <h4 className="mb-3">Stay updated with news on all our games</h4>
                                                    <p>Want to be the first to know about new releases? Subscribe out
                                                        newsletter!</p>
                                                </div>
                                                <form action="#">
                                                    <div
                                                        className="input-area mt-6 p-4 d-grid d-sm-flex align-items-center justify-content-between">
                                                        <input type="text" placeholder="Enter Your Email" />
                                                        <div className="btn-area mt-6 mt-sm-0">
                                                            <button className="box-style text-nowrap btn-box">
                                                                Send Massage
                                                            </button>
                                                        </div>
                                                    </div>
                                                </form>
                                                {/* <div className="instagram-post mt-10">
                                                    <h5 className="mb-5">Instagram post</h5>
                                                    <ul className="d-flex gap-2">
                                                        <li>
                                                            <a href="#!"><img src="assets/images/instagram-img-1.png"
                                                                alt="img" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#!"><img src="assets/images/instagram-img-2.png"
                                                                alt="img" /></a>
                                                        </li>
                                                        <li>
                                                            <a href="#!"><img src="assets/images/instagram-img-3.png"
                                                                alt="img" /></a>
                                                        </li>
                                                    </ul>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="footer-bottom py-8">
                            <div className="container">
                                <div className="row justify-content-center">
                                    <div className="col-md-6 text-center">
                                        <div className="copyright">
                                            <p>Copyright © {currentYear} <a href="#!">Throwcards</a> - All Right Reserved</p>
                                        </div>
                                    </div>
                                    <div className="col-md-6 text-center">
                                        <div className="copyright d-flex">
                                            <p><a href="/privacy-policy" target='_blank'>Privacy Policy</a></p>
                                            <p><a href="/terms&condition" target='_blank'>Terms & Conditions</a></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </footer>
            </div>
        </div>
    );
}
